import Image from "components/Image"
import cn from "classnames"

export default function SingleImage({ images, orientation }) {
  return (
    <Image
      src={images[0]?.filename}
      alt={images[0]?.alt}
      placeholder={images[0]?.blurDataURL ? "blur" : "empty"}
      blurDataURL={images[0]?.blurDataURL}
      width={610}
      height={300}
      className={cn("relative z-10 h-full w-full", {
        "object-cover": images[0]?.filename.endsWith("png"),
        "inset-0 object-cover lg:absolute": !images[0]?.filename.endsWith("png"),
        "rounded-bl-4xl": orientation === "content_first",
        "rounded-br-4xl": orientation === "image_first",
      })}
    />
  )
}
